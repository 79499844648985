import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import FeatureDeveloper from "../components/FeatureDeveloper/FeatureDeveloper"
import { Container } from "react-bootstrap"
import PropertyDevelopers from "../components/PropertyDevelopers/PropertyDevelopers"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
import SEO from "../components/seo"
import Accordion from "../components/AccordianComponent/Accordion"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"


const OffplanDevelopersLanding = ({ data, pageContext }) => {
  const [pageData, setPageData] = useState(data?.strapiPage)
  const [loading, setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string

  const propertyDevelopers = data?.allStrapiDeveloper?.edges?.length>0 ? 
  data?.allStrapiDeveloper?.edges : ""

  const sortedDeveloper = propertyDevelopers?.sort((a,b)=> parseInt(a.node?.sort) - parseInt(b.node?.sort));
  const featureDeveloper = sortedDeveloper?.filter(item=>item?.node?.isFeatured === true )


   //preview setup
  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        seo:"*",
        add_page_modules: {
          on: {
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.accordion":{
              populate:{
                add:{
                  populate:"*"
                }
              }
            },
            "page-modules.guides-cards":{
              populate:{
                items:{
                  populate:{
                    image:"*",
                    link:{
                      populate:{
                        parent:"*"
                      }
                    }
                  }
                }
              }
            },
            "page-modules.custom-modules":{
              populate:{
                select_module:"*"
              }
            },
            
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.slug = previewData?.slug
          myPrevData.mobile_image = previewData?.mobile_image
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(pageData => ({ ...pageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  if(loading){
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  }

  if(enablePreview){
    return null
  }

  //

  return (
    <Layout dark popularSearch={pageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={pageData?.choose_menu}
            pagename={pageData?.title}
            type="share-button"
          />
        </div>
      </Container>
      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
              item?.__component === "components.title-desc") && (
              <ContentComponent
                data={item}
                isPreviewEnabled={isPreviewEnabled}
                isOffplanLanding
              />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") && item?.select_module==="feature_developer"&& (
              <FeatureDeveloper featureDeveloperData={featureDeveloper} moduleData={item} pageData={pageData} />
            )}
             {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" ||
              item?.__component === "page-modules.accordion")
              && (
              <Accordion
                isPreviewEnabled={isPreviewEnabled}
                add_toggle={item?.add}
                title={item?.title}
                content={item?.content}
              />
            )}
             {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" ||
              item?.__component === "page-modules.custom-modules")
               && item?.select_module=== "developer_list"&& (
              <PropertyDevelopers developerList={sortedDeveloper} moduleData={item} pageData={pageData}/>
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" ||
              item?.__component ==="page-modules.guides-cards")&& (
                <GuidesCardComponent offplanDeveloper isPreviewEnabled={isPreviewEnabled} data={item} />
              )}
          
          </div>
        )
      })}
      
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  let getImg= PageData?.banner?.image?.url? PageData?.banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  let imageUrl= PageData?.banner?.image?.url

//new ggfx image
  const getImage =
  PageData?.banner?.image?.url
      ? PageData?.banner?.image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && PageData?.ggfx_results?.length > 0
      ? PageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  // filter image with image size
  const getOGImg =
    filterImage?.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: PageData.strapi_id,
      field: "metaog",
      contentType: "page"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl

  const metaTitle = PageData?.seo?.metaTitle ? `${ PageData?.seo?.metaTitle} | ` :"";
  const pageTitle = PageData?.title ? `${PageData?.title} | `:""

  //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} | haus & haus`;
    }
  }, [preMetaTitle]);
  //

  
  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle ? metaTitle : pageTitle} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl={pageImage}
      />
  )
}


export default OffplanDevelopersLanding


export const query = graphql`
  query ($page_id: String) {
    allStrapiDeveloper(filter: { publish: { eq: true } }) { 
      edges {
      node{
        developer_name
        slug
        developer_image{
          url
        }
        sort
        developer_banner{
          image{
            url
          }
          banner_content{
            data{
              banner_content
            }
          }
        }
        publish
        isFeatured
      }
      }
     }

    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {

         ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
            id
            __typename
            items {
              cta_title
              image{
                url
              }
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              content {
                data {
                  content
                }
              }
            }
          }

          ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
            id
            __typename
            select_module
            title
  
            content {
              data {
                content
              }
            }
          }

          ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
            __typename
            id
            add {
              content {
                data {
                  content
                }
              }
              title
            }
            title
            content {
              data {
                content
              }
            }
          }

        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }
      }
    }
  }
`
